import moment from 'moment';
import { arrayToTree } from 'performant-array-to-tree';

export const getNthItemFromPathname = (pathname, n) => {
  const splitPath = pathname.split('/');
  return splitPath[n];
};

export const parseCategory = (slug) => {
  let categoryShorthand;
  let categoryName;
  let categoryColor;
  let categorySlug;
  let catgegoryId;

  switch (slug) {
    case 'operations':
      catgegoryId = `${process.env.REACT_APP_OPERATIONS}`;
      categoryShorthand = 'ope';
      categorySlug = slug;
      categoryName = 'OPS | HR';
      categoryColor = '#76BD87';
      break;
    case 'sm':
      catgegoryId = `${process.env.REACT_APP_SM}`;
      categoryShorthand = 'snm';
      categorySlug = slug;
      categoryName = 'S&M';
      categoryColor = '#FF7DD7';
      break;
    case 'fb':
      catgegoryId = `${process.env.REACT_APP_FB}`;
      categoryShorthand = 'fnb';
      categorySlug = slug;
      categoryName = 'F&B';
      categoryColor = '#ff6600';
      break;
    case 'sustainability':
      catgegoryId = null;
      categoryShorthand = 'sustainability';
      categorySlug = slug;
      categoryName = 'Sustainability';
      categoryColor = '#7EC625';
      break;
    default:
      break;
  }

  return {
    categoryName,
    categoryShorthand,
    categoryColor,
    categorySlug,
    catgegoryId
  };
};

export const parseCategoryID = (id) => {
  let categoryShorthand;
  let categoryName;
  let categoryColor;
  let categorySlug;

  switch (id) {
    case `${process.env.REACT_APP_OPERATIONS}`:
      categoryShorthand = 'ope';
      categoryName = 'OPS | HR';
      categoryColor = '#76BD87';
      categorySlug = 'operations';
      break;
    case `${process.env.REACT_APP_SM}`:
      categoryShorthand = 'snm';
      categoryName = 'S&M';
      categoryColor = '#FF7DD7';
      categorySlug = 'sm';
      break;
    case `${process.env.REACT_APP_FB}`:
      categoryShorthand = 'fnb';
      categoryName = 'F&B';
      categoryColor = '#FF6600';
      categorySlug = 'fb';
      break;
    default:
      break;
  }

  return {
    categoryName,
    categoryShorthand,
    categoryColor,
    categorySlug,
  };
};

export const simpleUnflatten = (array) => {
  const topLevel = array.filter((c) => !c.parent);
  topLevel.sort(sortByCreated);
  topLevel.forEach((t) => {
    t.children = array.filter((item) => item.parent === t.id);
    // t.children.sort(sortByCreated)
  });
  return topLevel;
};

export const getCommentTree = (parentId, allComments) => {
  let newComments = []
  const filteredComments = allComments.filter((e) => e.parent === parentId)

  if (filteredComments.length) {
      for (const line of filteredComments) {
          let childs = getCommentTree(line.id, allComments).map(
            (e) => ({ ...e, parent: { id: line.id, user: line.user }})
          )

          newComments.push({
              ...line,
              children: childs?.length ? childs : [],
          })
      }
  }

  return newComments
}

const getFlatChilds = (childs) => {
  let flattenChilds = [ ...childs,]
  for (const child of childs) {
    if (child.children.length) {
      flattenChilds = [...flattenChilds, ...getFlatChilds(child.children)]
    }
  }

  return flattenChilds
}

export const flattenTree = (data) => {
    for (let i = 0; i < data.length; i++) {
      const element = getFlatChilds(data[i].children)
      data[i].children = element
    }

  return data
}

export const unflatten = (array) => {
  return arrayToTree(array, { parentId: 'parent', dataField: null });
};

export const sortByCreated = (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix();

export const sortByReleased = (a, b) => moment(b.releaseDate).unix() - moment(a.releaseDate).unix();

export const filterUnreleased = (video) =>
  moment(video.releaseDate).isBefore(moment()) || moment(video.releaseDate).isSame(moment(), 'day');

export const seriesFilter = (video) => (
    video.video && video.video.releaseDate ?
  moment(video.video.releaseDate).isBefore(moment()) ||
	moment(video.video.releaseDate).isSame(moment(), 'day') : true
);

export const orderByName = (a, b) => {
  return a.name.charCodeAt(0) - b.name.charCodeAt(0);
};

export const orderByOrder = (a, b) => {
  return a.order - b.order;
}
